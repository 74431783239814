import { render, staticRenderFns } from "./CommissionSolshareInProgress.vue?vue&type=template&id=4620a003&scoped=true&"
import script from "./CommissionSolshareInProgress.vue?vue&type=script&lang=js&"
export * from "./CommissionSolshareInProgress.vue?vue&type=script&lang=js&"
import style0 from "./CommissionSolshareInProgress.vue?vue&type=style&index=0&id=4620a003&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4620a003",
  null
  
)

export default component.exports