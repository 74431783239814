import axios from "axios";
import store from "../store/index";
import * as Sentry from "@sentry/browser";

const Api = axios.create();

Api.interceptors.request.use(
  (config) => {
    if (store.state.user.token) {
      config.headers.Authorization = `Bearer ${store.state.user.token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

Api.interceptors.response.use(
  (response) => response,
  async (error) => {
    if (error && error.response) {
      console.log(error.response);
      switch (error.response.status) {
        case 401:
          await store.dispatch("loginExpire");
          break;
        default:
          if (error.response.status >= 500) {
            Sentry.captureMessage(
              `Error when requesting ${error.response.config.url}`,
              {
                tags: {
                  url: error.response.config.url,
                  message: error.response.data,
                  status: error.response.status,
                  method: error.response.config.method,
                  payload: error.response.config.data,
                },
              }
            );
          }

          await store.dispatch("throwException", {
            message:
              error.response.data.message ||
              error.response.data ||
              `An error has occurred when making request to ${error.response.config.url} (code: ${error.response.status}).`,
          });
      }
    }
    return Promise.reject(error.message);
  }
);

export default Api;
