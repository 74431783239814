var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "content"
  }, [_vm._m(0), _c('div', {
    staticClass: "p"
  }, [_vm._v(" Note: If extended the point of connection should be checked for continuity ")]), _c('div', {
    staticClass: "p",
    attrs: {
      "data-tn": "ct-error-list"
    }
  }, [_vm._v(" Connection to check: "), _c('ul', {
    staticClass: "p"
  }, _vm._l(_vm.CTNotReadingUnits, function (unit, i) {
    return _c('li', {
      key: i
    }, [_vm._v(_vm._s(unit))]);
  }), 0)]), _c('img', {
    attrs: {
      "src": require("../../../../../assets/png/ct-connector.jpg")
    }
  }), _c('back-and-next', {
    attrs: {
      "next-text": "Return to results",
      "to": "/commission/20",
      "data-tn": "ct-not-reading-au/4"
    }
  })], 1);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "p"
  }, [_c('b', [_vm._v("At the SolShare")]), _vm._v(" CT connector check the CT cables are fully inserted and secure in the terminal block. ")]);

}]

export { render, staticRenderFns }