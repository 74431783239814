<template>
  <div class="content">
    <div class="p">
      We have identified the following connections need some attention
    </div>

    <div v-if="CTReversedUnits.length > 0" class="p">
      {{ CTReversedUnits.join(", ") }} -
      <b>CT is reading negative, reverse polarity</b>
      <yellow-button
        class="troubleshoot reverse"
        to="/commission/error/reversed-ct-au/1"
      >
        Troubleshoot
      </yellow-button>
    </div>

    <div v-if="CTNotReadingUnits.length > 0" class="p">
      {{ CTNotReadingUnits.join(", ") }} - <b>CT not reading</b>
      <yellow-button
        class="troubleshoot not-reading"
        to="/commission/error/ct-not-reading-au/1"
      >
        Troubleshoot
      </yellow-button>
    </div>

    <div v-if="CTReadingIncorrectUnits.length > 0" class="p">
      {{ CTReadingIncorrectUnits.join(", ") }} -
      <b data-tn="ct-error-text">CT reading incorrect</b>
      <yellow-button
        to="/commission/error/ct-reading-incorrect-au/1"
        class="troubleshoot reading-incorrect"
      >
        Troubleshoot
      </yellow-button>
    </div>
    <yellow-button class="retry" data-tn="retry" @click="tryAgainClicked">
      Fixed? Re-try commissioning
    </yellow-button>
  </div>
</template>

<script>
import { CommissionErrorMixin } from "../../../../mixins/commissionError";

export default {
  mixins: [CommissionErrorMixin],
};
</script>

<style scoped lang="less">
.content {
  .p {
    margin-top: 20px;
  }

  .troubleshoot {
    margin-top: 20px;
    font-weight: bold;
  }

  .retry {
    margin-top: 20px;
  }
}
</style>
