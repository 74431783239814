<template>
  <div class="content">
    <div class="p">
      <b>At the SolShare</b> CT connector check the CT cables are fully inserted
      and secure in the terminal block.
    </div>

    <div class="p">
      Note: If extended the point of connection should be checked for continuity
    </div>

    <img src="../../../../../assets/png/ct-connector.jpg" />

    <div class="p" data-tn="ct-error-list">
      Connection to check:
      <ul class="p">
        <li v-for="(unit, i) in CTNotReadingUnits" :key="i">{{ unit }}</li>
      </ul>
    </div>

    <back-and-next
      next-text="Return to results"
      to="/commission/20"
      data-tn="ct-not-reading-uk/5"
    >
    </back-and-next>
  </div>
</template>

<script>
import { CommissionErrorMixin } from "../../../../../mixins/commissionError";

export default {
  mixins: [CommissionErrorMixin],
};
</script>

<style scoped lang="less">
.content {
  .p {
    margin-top: 20px;
  }
  img {
    width: 100%;
    margin-top: 20px;
  }
}
</style>
