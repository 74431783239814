<template>
  <component :is="currentComponent"></component>
</template>

<script>
import DefaultErrorScreen from "./Error/Gen1/DefaultErrorScreen.vue";
import PhaseToPhaseAU from "./Error/Gen2/PhaseToPhase/PhaseToPhase.AU.1.vue";
import PhaseToPhaseUK from "./Error/Gen2/PhaseToPhase/PhaseToPhase.UK.1.vue";
import NoVoltageReferenceAUGen1 from "./Error/Gen1/NoVoltageReference/NoVoltageReference.AU.1.vue";
import NoVoltageReferenceAUGen2 from "./Error/Gen2/NoVoltageReference/NoVoltageReference.AU.1.vue";
import NoVoltageReferenceUKGen2 from "./Error/Gen2/NoVoltageReference/NoVoltageReference.UK.1.vue";
import InverterNoOutputAUUKGen1Gen2 from "./Error/Gen1-and-2/InverterNoOutput/InverterNoOutput.AU.UK.vue";
import CTErrorsAU from "./Error/Gen1-and-2/CTErrors/CTErrors.AU.vue";
import CTErrorsUK from "./Error/Gen1-and-2/CTErrors/CTErrors.UK.vue";
import { generation } from "../util/solshare-serial";
import {
  COMMISSION_LOG_ERROR_CODE,
  COUNTRY_CODE,
  GENERATION,
} from "../util/constants";

export default {
  components: {
    DefaultErrorScreen,
    PhaseToPhaseAU,
    PhaseToPhaseUK,
    NoVoltageReferenceAUGen1,
    CTErrorsAU,
    CTErrorsUK,
  },
  computed: {
    currentComponent() {
      if (
        generation(this.$store.state.project.serialNumber) === GENERATION.ONE
      ) {
        switch (this.$store.state.commissionLog.logs?.[0]?.errorcode) {
          case COMMISSION_LOG_ERROR_CODE.NO_VOLTAGE:
            return this.noVoltageReferenceGen1();
          case COMMISSION_LOG_ERROR_CODE.INVERTER_NOT_OUTPUTTING:
            return this.inverterNoOutputting();
          case COMMISSION_LOG_ERROR_CODE.REVERSED:
          case COMMISSION_LOG_ERROR_CODE.UNKNOWN:
          case COMMISSION_LOG_ERROR_CODE.WRONG_CT:
          case COMMISSION_LOG_ERROR_CODE.SWAPPED:
          case COMMISSION_LOG_ERROR_CODE.NO_GRID:
            return this.ctErrors();
          default:
            return DefaultErrorScreen;
        }
      }

      if (
        generation(this.$store.state.project.serialNumber) === GENERATION.TWO
      ) {
        switch (this.$store.state.commissionLog.logs?.[0]?.errorcode) {
          case COMMISSION_LOG_ERROR_CODE.PHASE_SWAPPED:
            return this.phaseToPhaseGen2();
          case COMMISSION_LOG_ERROR_CODE.NO_VOLTAGE:
            return this.noVoltageReferenceGen2();
          case COMMISSION_LOG_ERROR_CODE.INVERTER_NOT_OUTPUTTING:
            return this.inverterNoOutputting();
          case COMMISSION_LOG_ERROR_CODE.REVERSED:
          case COMMISSION_LOG_ERROR_CODE.UNKNOWN:
          case COMMISSION_LOG_ERROR_CODE.WRONG_CT:
          case COMMISSION_LOG_ERROR_CODE.SWAPPED:
          case COMMISSION_LOG_ERROR_CODE.NO_GRID:
            return this.ctErrors();
          default:
            return DefaultErrorScreen;
        }
      }

      return DefaultErrorScreen;
    },
  },
  methods: {
    phaseToPhaseGen2() {
      switch (this.$store.state.project.address.country) {
        case COUNTRY_CODE.Australia:
        case COUNTRY_CODE["New Zealand"]:
          return PhaseToPhaseAU;
        case COUNTRY_CODE["United Kingdom"]:
        case COUNTRY_CODE.Germany:
          return PhaseToPhaseUK;
        default:
          return DefaultErrorScreen;
      }
    },
    noVoltageReferenceGen1() {
      switch (this.$store.state.project.address.country) {
        case COUNTRY_CODE.Australia:
        case COUNTRY_CODE["New Zealand"]:
          return NoVoltageReferenceAUGen1;
        default:
          return DefaultErrorScreen;
      }
    },
    noVoltageReferenceGen2() {
      switch (this.$store.state.project.address.country) {
        case COUNTRY_CODE.Australia:
        case COUNTRY_CODE["New Zealand"]:
          return NoVoltageReferenceAUGen2;
        case COUNTRY_CODE["United Kingdom"]:
        case COUNTRY_CODE.Germany:
          return NoVoltageReferenceUKGen2;
        default:
          return DefaultErrorScreen;
      }
    },
    inverterNoOutputting() {
      switch (this.$store.state.project.address.country) {
        case COUNTRY_CODE.Australia:
        case COUNTRY_CODE["New Zealand"]:
        case COUNTRY_CODE["United Kingdom"]:
        case COUNTRY_CODE.Germany:
          return InverterNoOutputAUUKGen1Gen2;
        default:
          return DefaultErrorScreen;
      }
    },
    ctErrors() {
      switch (this.$store.state.project.address.country) {
        case COUNTRY_CODE.Australia:
        case COUNTRY_CODE["New Zealand"]:
          return CTErrorsAU;
        case COUNTRY_CODE["United Kingdom"]:
        case COUNTRY_CODE.Germany:
          return CTErrorsUK;
        default:
          return DefaultErrorScreen;
      }
    },
  },
};
</script>

<style lang="less" scoped></style>
