<template>
  <div class="content">
    <div class="info-text">
      Before commissioning please confirm the following
    </div>

    <div class="checklist">
      <div class="checklist-item">
        <label>
          <input
            v-model="checkItems.training"
            type="checkbox"
            :disabled="confirmed"
            data-tn="confirm-training-checkbox-1"
          />
          <span class="checklist-text">
            I am the account owner, {{ $store.state.user.email }}, and have
            completed the required Allume training.
          </span>
        </label>
      </div>
      <div class="warning-text">Sharing of the accounts is not permitted.</div>

      <div class="checklist-item">
        <label>
          <input
            v-model="checkItems.clearance"
            type="checkbox"
            :disabled="confirmed"
            data-tn="confirm-training-checkbox-2"
          />
          <span class="checklist-text">
            SolShare has at least 300mm clearance above and 150mm either side
            (including any obstacles such as containment).
          </span>
        </label>
      </div>
      <div class="warning-text">
        Clearance is required for serviceability of SolShare.
      </div>

      <div class="checklist-item">
        <label>
          <input
            v-model="checkItems.phasing"
            type="checkbox"
            :disabled="confirmed"
            data-tn="confirm-training-checkbox-3"
          />
          <span class="checklist-text">
            I have checked voltages and phasing to confirm no phase miswiring.
            An incorrect phase wiring can damage SolShare and void warranty.
          </span>
        </label>
      </div>
      <div class="warning-text">
        Incorrect phase wiring will damage SolShare.
      </div>
    </div>

    <div>
      <WarningSVG class="warning-icon" /> All items above are essential for
      warranty.
    </div>

    <yellow-button
      data-tn="confirm-training-next"
      :block="false"
      :disabled="!allChecked"
      @click="nextClicked"
    >
      NEXT
    </yellow-button>
  </div>
</template>

<script>
import WifiHighlightedSVG from "../assets/svg/wifi-highlighted.svg";
import WarningSVG from "../assets/svg/warning-yellow.svg";

export default {
  components: { WarningSVG },
  data() {
    return {
      WifiHighlightedSVG,
      confirmed: false,
      checkItems: {
        training: false,
        clearance: false,
        phasing: false,
      },
    };
  },
  computed: {
    allChecked() {
      return Object.values(this.checkItems).every((value) => value === true);
    },
  },
  methods: {
    nextClicked() {
      this.$router.push("/commission/1");
    },
  },
};
</script>

<style lang="less" scoped>
.content {
  text-align: left;
  padding: 20px;

  .info-text {
    margin-bottom: 30px;
    font-weight: bold;
  }

  .checklist {
    margin-bottom: 30px;

    .checklist-item {
      margin-bottom: 20px;

      label {
        display: flex;
        align-items: flex-start;
        cursor: pointer;
      }

      input[type="checkbox"] {
        margin-right: 10px;
        margin-top: 4px;
      }
    }
  }

  .checklist-text {
    margin-left: 5px;
  }

  .warning-text {
    color: #413f3f;
    margin-bottom: 30px;
    font-style: italic;
    margin-top: 10px;
    margin-left: 27px;
  }

  .warning-icon {
    width: 25px;
    height: 25px;
    margin-bottom: -5px;
  }

  .yellow-button {
    display: block;
    width: 100%;
    margin-top: 50px;
  }
}
</style>
