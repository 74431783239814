var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "content"
  }, [_vm._m(0), _vm._m(1), _c('div', {
    staticClass: "p",
    attrs: {
      "data-tn": "ct-error-list"
    }
  }, [_vm._v(" Connection to check: "), _c('ul', {
    staticClass: "p"
  }, _vm._l(_vm.CTReadingIncorrectUnits, function (unit, i) {
    return _c('li', {
      key: i
    }, [_vm._v(" " + _vm._s(unit) + " ")]);
  }), 0)]), _c('back-and-next', {
    attrs: {
      "next-text": "Return to results",
      "to": "/commission/20",
      "data-tn": "ct-reading-incorrect-au/5"
    }
  })], 1);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "p"
  }, [_c('b', [_vm._v("If CT extended")]), _vm._v(" check the integrity at the point of extension. ")]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "p"
  }, [_vm._v(" Note that when extending CTs Allume recommend: "), _c('ul', {
    staticClass: "p"
  }, [_c('li', [_vm._v("Shielded, twisted pair cable of 0.25mm2 (24AWG) or larger")]), _c('li', [_c('b', [_vm._v("Splicing connector or terminal block")])])])]);

}]

export { render, staticRenderFns }