<template>
  <div class="content">
    <progress-bar :percent="70" />
    <div class="info-text">Commissioning in progress</div>

    <div class="center">
      <circle-progress ref="progressBar" />
      <div class="notice" data-tn="commission-in-progress-progress-detail">
        {{ progressDetail }}
      </div>
      <div class="note" data-tn="commission-in-progress-progress-estimate">
        {{ taskEstimatedTime }}
      </div>
      <div
        class="note"
        data-tn="commission-in-progress-progress-total-estimate"
      >
        {{ totalEstimatedTime }}
      </div>
    </div>
  </div>
</template>

<script>
import { COMMISSION_STATUS } from "../util/constants";

export default {
  name: "CommissioningProgress",
  data() {
    return { progressDetail: "" };
  },
  computed: {
    configStatus() {
      return this.$store.state.configStatus;
    },
    commissionLog() {
      return this.$store.state.commissionLog;
    },
    taskEstimatedTime() {
      if (this.commissionLog.duration) {
        const minutes = this.commissionLog.duration / 60000;
        return `Estimated task time: ${minutes} ${
          minutes > 1 ? "minutes" : "minute"
        }`;
      }
      return "";
    },
    totalEstimatedTime() {
      if (this.$store.state.commissionLog.totalDuration) {
        return `Estimated total time: ${
          this.$store.state.commissionLog.totalDuration / 60000
        } minutes`;
      }
      return "";
    },
  },
  watch: {
    configStatus(newVal, oldVal) {
      if (oldVal !== newVal) {
        this.updateConfigProgress();
      }
    },
    commissionLog(newVal, oldVal) {
      if (newVal.status !== COMMISSION_STATUS.PENDING) {
        this.$refs.progressBar.complete();
        return;
      }

      if (oldVal?.logs?.[0]?.progressCode !== newVal?.logs?.[0]?.progressCode) {
        this.$refs.progressBar.setValue(newVal.startPercent);
        this.updateCommissionProgress();
      }
    },
  },
  mounted() {
    this.updateConfigProgress();
  },
  methods: {
    async updateConfigProgress() {
      if (this.configStatus === COMMISSION_STATUS.PENDING) {
        // if user refreshes the page or redirected here from search meter page
        // will need to start polling again
        if (!this.$store.state.isPollingConfigStatus) {
          this.$store
            .dispatch("pollingConfigStatus")
            .catch((e) => console.error(e));
        }
        this.progressDetail = `Task: Uploading SolShare configuration`;
        this.$refs.progressBar.startFrom(3, 10, 2 * 60000);
      } else if (this.configStatus === COMMISSION_STATUS.SUCCESS) {
        // if user refreshes the page or redirected here from search meter page
        // will need to start polling again
        if (!this.$store.state.isPollingCommissionLog) {
          this.$store
            .dispatch("pollingCommissionLog")
            .catch((e) => console.error(e));
        }
        this.$refs.progressBar.setValue(11);
        this.updateCommissionProgress();
      }
    },
    updateCommissionProgress() {
      const currentTask = this.commissionLog.logs?.[0]?.progress;
      if (currentTask) {
        this.progressDetail = `Task: ${currentTask}`;
      }
      if (this.commissionLog.startPercent !== null) {
        this.$refs.progressBar.startFrom(
          this.commissionLog.startPercent,
          this.commissionLog.endPercent,
          this.commissionLog.duration
        );
      }
    },
  },
};
</script>

<style lang="less" scoped>
.content {
  .info-text {
    margin-top: 28px;
    margin-bottom: 48px;
  }

  .big-progress {
    margin-bottom: 40px;
  }

  .notice {
    margin-bottom: 30px;
    font-size: 25px;
    color: #005149;
    font-family: "Helvetica Light", sans-serif;
    line-height: 29px;
  }

  .note {
    margin-bottom: 10px;
    font-size: 17px;
    font-family: "Helvetica Light", sans-serif;
  }

  .center {
    text-align: center;
  }
}
</style>
