import { render, staticRenderFns } from "./CTReadingIncorrect.UK.1.vue?vue&type=template&id=5a49bc08&scoped=true&"
import script from "./CTReadingIncorrect.UK.1.vue?vue&type=script&lang=js&"
export * from "./CTReadingIncorrect.UK.1.vue?vue&type=script&lang=js&"
import style0 from "./CTReadingIncorrect.UK.1.vue?vue&type=style&index=0&id=5a49bc08&prod&scoped=true&lang=less&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5a49bc08",
  null
  
)

export default component.exports