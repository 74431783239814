var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "content"
  }, [_c('div', {
    staticClass: "info-text"
  }, [_vm._v(" Before commissioning please confirm the following ")]), _c('div', {
    staticClass: "checklist"
  }, [_c('div', {
    staticClass: "checklist-item"
  }, [_c('label', [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.checkItems.training,
      expression: "checkItems.training"
    }],
    attrs: {
      "type": "checkbox",
      "disabled": _vm.confirmed,
      "data-tn": "confirm-training-checkbox-1"
    },
    domProps: {
      "checked": Array.isArray(_vm.checkItems.training) ? _vm._i(_vm.checkItems.training, null) > -1 : _vm.checkItems.training
    },
    on: {
      "change": function ($event) {
        var $$a = _vm.checkItems.training,
          $$el = $event.target,
          $$c = $$el.checked ? true : false;
        if (Array.isArray($$a)) {
          var $$v = null,
            $$i = _vm._i($$a, $$v);
          if ($$el.checked) {
            $$i < 0 && _vm.$set(_vm.checkItems, "training", $$a.concat([$$v]));
          } else {
            $$i > -1 && _vm.$set(_vm.checkItems, "training", $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
          }
        } else {
          _vm.$set(_vm.checkItems, "training", $$c);
        }
      }
    }
  }), _c('span', {
    staticClass: "checklist-text"
  }, [_vm._v(" I am the account owner, " + _vm._s(_vm.$store.state.user.email) + ", and have completed the required Allume training. ")])])]), _c('div', {
    staticClass: "warning-text"
  }, [_vm._v("Sharing of the accounts is not permitted.")]), _c('div', {
    staticClass: "checklist-item"
  }, [_c('label', [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.checkItems.clearance,
      expression: "checkItems.clearance"
    }],
    attrs: {
      "type": "checkbox",
      "disabled": _vm.confirmed,
      "data-tn": "confirm-training-checkbox-2"
    },
    domProps: {
      "checked": Array.isArray(_vm.checkItems.clearance) ? _vm._i(_vm.checkItems.clearance, null) > -1 : _vm.checkItems.clearance
    },
    on: {
      "change": function ($event) {
        var $$a = _vm.checkItems.clearance,
          $$el = $event.target,
          $$c = $$el.checked ? true : false;
        if (Array.isArray($$a)) {
          var $$v = null,
            $$i = _vm._i($$a, $$v);
          if ($$el.checked) {
            $$i < 0 && _vm.$set(_vm.checkItems, "clearance", $$a.concat([$$v]));
          } else {
            $$i > -1 && _vm.$set(_vm.checkItems, "clearance", $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
          }
        } else {
          _vm.$set(_vm.checkItems, "clearance", $$c);
        }
      }
    }
  }), _c('span', {
    staticClass: "checklist-text"
  }, [_vm._v(" SolShare has at least 300mm clearance above and 150mm either side (including any obstacles such as containment). ")])])]), _c('div', {
    staticClass: "warning-text"
  }, [_vm._v(" Clearance is required for serviceability of SolShare. ")]), _c('div', {
    staticClass: "checklist-item"
  }, [_c('label', [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.checkItems.phasing,
      expression: "checkItems.phasing"
    }],
    attrs: {
      "type": "checkbox",
      "disabled": _vm.confirmed,
      "data-tn": "confirm-training-checkbox-3"
    },
    domProps: {
      "checked": Array.isArray(_vm.checkItems.phasing) ? _vm._i(_vm.checkItems.phasing, null) > -1 : _vm.checkItems.phasing
    },
    on: {
      "change": function ($event) {
        var $$a = _vm.checkItems.phasing,
          $$el = $event.target,
          $$c = $$el.checked ? true : false;
        if (Array.isArray($$a)) {
          var $$v = null,
            $$i = _vm._i($$a, $$v);
          if ($$el.checked) {
            $$i < 0 && _vm.$set(_vm.checkItems, "phasing", $$a.concat([$$v]));
          } else {
            $$i > -1 && _vm.$set(_vm.checkItems, "phasing", $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
          }
        } else {
          _vm.$set(_vm.checkItems, "phasing", $$c);
        }
      }
    }
  }), _c('span', {
    staticClass: "checklist-text"
  }, [_vm._v(" I have checked voltages and phasing to confirm no phase miswiring. An incorrect phase wiring can damage SolShare and void warranty. ")])])]), _c('div', {
    staticClass: "warning-text"
  }, [_vm._v(" Incorrect phase wiring will damage SolShare. ")])]), _c('div', [_c('WarningSVG', {
    staticClass: "warning-icon"
  }), _vm._v(" All items above are essential for warranty. ")], 1), _c('yellow-button', {
    attrs: {
      "data-tn": "confirm-training-next",
      "block": false,
      "disabled": !_vm.allChecked
    },
    on: {
      "click": _vm.nextClicked
    }
  }, [_vm._v(" NEXT ")])], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }