var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "content"
  }, [_vm._m(0), _c('div', {
    staticClass: "p"
  }, [_vm._v("Note the location of the clip on CT rating")]), _c('img', {
    attrs: {
      "src": require("../../../../../assets/png/ct-rating.png")
    }
  }), _c('div', {
    staticClass: "p",
    attrs: {
      "data-tn": "ct-error-list"
    }
  }, [_vm._v(" Connection to check: "), _c('ul', {
    staticClass: "p"
  }, _vm._l(_vm.CTReadingIncorrectUnits, function (unit, i) {
    return _c('li', {
      key: i
    }, [_vm._v(" " + _vm._s(unit) + " ")]);
  }), 0)]), _c('back-and-next', {
    attrs: {
      "next-text": "Next - CT Installation",
      "data-tn": "ct-reading-incorrect-au/4",
      "to": "/commission/error/ct-reading-incorrect-au/5"
    }
  })], 1);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "p"
  }, [_c('b', [_vm._v("At the point of CT install")]), _vm._v(" check the rating of the clip on CT for each of the connections listed below. ")]);

}]

export { render, staticRenderFns }