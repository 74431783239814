<template>
  <div class="content">
    <progress-bar :percent="10" />

    <div class="info-text">
      <p>Sorry! We can't find your SolShare online.</p>

      <p>Please ensure Solshare is connected to Wi-Fi.</p>
    </div>

    <yellow-button class="yellow-btn" @click="howToClicked">
      HOW TO SETUP WI-FI
    </yellow-button>
    <white-button bordered @click="tryAgainClicked">
      OK, LET'S TRY AGAIN
    </white-button>
  </div>
</template>

<script>
import { generation } from "../util/solshare-serial";
import { GENERATION } from "../util/constants";

export default {
  methods: {
    async tryAgainClicked() {
      await this.$store.dispatch("searchMeter", {});
    },
    async howToClicked() {
      const gen = generation(this.$store.state.project.serialNumber);
      if (gen === GENERATION.ONE) {
        window.open(
          "https://info.allumeenergy.com/hubfs/ANZ/0339_Changing-Wi-Fi-credentials_AUS.pdf",
          "_blank"
        );
      } else {
        window.open(
          "https://info.allumeenergy.com/hubfs/ANZ/0964-ChangingSolShareWiFiCredentials.pdf",
          "_blank"
        );
      }
    },
  },
};
</script>

<style lang="less" scoped>
.content {
  .info-text {
    margin-top: 26px;
    margin-bottom: 40px;
  }

  .yellow-btn {
    margin-bottom: 20px;
  }
}
</style>
