var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "content"
  }, [_c('div', {
    staticClass: "p"
  }, [_vm._v("How to troubleshoot a CT that is not reading")]), _vm._m(0), _c('div', {
    staticClass: "p",
    attrs: {
      "data-tn": "ct-error-list"
    }
  }, [_vm._v(" Connection to check: "), _c('ul', {
    staticClass: "p"
  }, _vm._l(_vm.CTNotReadingUnits, function (unit, i) {
    return _c('li', {
      key: i
    }, [_vm._v(_vm._s(unit))]);
  }), 0)]), _c('back-and-next', {
    attrs: {
      "next-text": "Next - CT Installation",
      "to": "/commission/error/ct-not-reading-uk/2",
      "data-tn": "ct-not-reading-uk/1"
    }
  })], 1);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "p"
  }, [_vm._v(" Each CT must be "), _c('ul', {
    staticClass: "p"
  }, [_c('li', [_vm._v("Clipped around the correct cable")]), _c('li', [_vm._v("Clipped in the correct orientation")]), _c('li', [_vm._v("Correctly fitted to the SolShare connector")]), _c('li', [_vm._v("If extended CT, good electrical contact at extension point")])])]);

}]

export { render, staticRenderFns }