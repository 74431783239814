<template>
  <div class="content">
    <div class="p">
      <b>At the point of CT install</b> check that each CT is
      <ul class="p">
        <li>clipped around the correct cable for that connection</li>
        <li>clipped on the service side of the solar connection</li>
      </ul>
    </div>

    <img src="../../../../../assets/png/ct-point-of-connection.png" />

    <div class="p" data-tn="ct-error-list">
      Connection to check:
      <ul class="p">
        <li v-for="(unit, i) in CTNotReadingUnits" :key="i">{{ unit }}</li>
      </ul>
    </div>

    <back-and-next
      next-text="Next - CT Installation"
      to="/commission/error/ct-not-reading-au/3"
      data-tn="ct-not-reading-au/2"
    >
    </back-and-next>
  </div>
</template>

<script>
import { CommissionErrorMixin } from "../../../../../mixins/commissionError";

export default {
  mixins: [CommissionErrorMixin],
};
</script>

<style scoped lang="less">
.content {
  .p {
    margin-top: 20px;
  }

  img {
    width: 100%;
  }
}
</style>
