var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "content"
  }, [_c('div', {
    staticClass: "p"
  }, [_vm._v(" We have identified the following connections need some attention ")]), _vm.CTReversedUnits.length > 0 ? _c('div', {
    staticClass: "p"
  }, [_vm._v(" " + _vm._s(_vm.CTReversedUnits.join(", ")) + " - "), _c('b', [_vm._v("CT is reading negative, reverse polarity")]), _c('yellow-button', {
    staticClass: "troubleshoot reverse",
    attrs: {
      "to": "/commission/error/reversed-ct-au/1"
    }
  }, [_vm._v(" Troubleshoot ")])], 1) : _vm._e(), _vm.CTNotReadingUnits.length > 0 ? _c('div', {
    staticClass: "p"
  }, [_vm._v(" " + _vm._s(_vm.CTNotReadingUnits.join(", ")) + " - "), _c('b', [_vm._v("CT not reading")]), _c('yellow-button', {
    staticClass: "troubleshoot not-reading",
    attrs: {
      "to": "/commission/error/ct-not-reading-au/1"
    }
  }, [_vm._v(" Troubleshoot ")])], 1) : _vm._e(), _vm.CTReadingIncorrectUnits.length > 0 ? _c('div', {
    staticClass: "p"
  }, [_vm._v(" " + _vm._s(_vm.CTReadingIncorrectUnits.join(", ")) + " - "), _c('b', {
    attrs: {
      "data-tn": "ct-error-text"
    }
  }, [_vm._v("CT reading incorrect")]), _c('yellow-button', {
    staticClass: "troubleshoot reading-incorrect",
    attrs: {
      "to": "/commission/error/ct-reading-incorrect-au/1"
    }
  }, [_vm._v(" Troubleshoot ")])], 1) : _vm._e(), _c('yellow-button', {
    staticClass: "retry",
    attrs: {
      "data-tn": "retry"
    },
    on: {
      "click": _vm.tryAgainClicked
    }
  }, [_vm._v(" Fixed? Re-try commissioning ")])], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }