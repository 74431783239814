<template>
  <div class="content">
    <div class="p">
      <b>At the point of CT install</b> ensure that the arrow is pointing to the
      load. For connections listed below check the CT.
    </div>

    <div class="p">Note the arrow markings on the base of the clip CT</div>

    <img src="../../../../../assets/png/ct-orientation.png" />

    <div class="p" data-tn="ct-error-list">
      Connection to check:
      <ul class="p">
        <li v-for="(unit, i) in CTNotReadingUnits" :key="i">{{ unit }}</li>
      </ul>
    </div>

    <back-and-next
      next-text="Next - At Solshare"
      to="/commission/error/ct-not-reading-au/4"
      data-tn="ct-not-reading-au/3"
    >
    </back-and-next>
  </div>
</template>

<script>
import { CommissionErrorMixin } from "../../../../../mixins/commissionError";

export default {
  mixins: [CommissionErrorMixin],
};
</script>

<style scoped lang="less">
.content {
  .p {
    margin-top: 20px;
  }

  img {
    margin-top: 20px;
    width: 100%;
  }
}
</style>
