<template>
  <div class="content">
    <div class="p">
      <b>If CT extended</b> check the integrity at the point of extension.
    </div>

    <div class="p">
      Note that when extending CTs Allume recommend:
      <ul class="p">
        <li>Shielded, twisted pair cable of 0.25mm2 (24AWG) or larger</li>
        <li><b>Splicing connector or terminal block</b></li>
      </ul>
    </div>

    <div class="p" data-tn="ct-error-list">
      Connection to check:
      <ul class="p">
        <li v-for="(unit, i) in CTReadingIncorrectUnits" :key="i">
          {{ unit }}
        </li>
      </ul>
    </div>

    <back-and-next
      next-text="Return to results"
      to="/commission/20"
      data-tn="ct-reading-incorrect-au/5"
    >
    </back-and-next>
  </div>
</template>

<script>
import { CommissionErrorMixin } from "../../../../../mixins/commissionError";

export default {
  mixins: [CommissionErrorMixin],
};
</script>

<style scoped lang="less">
.content {
  .p {
    margin-top: 20px;
  }
}
</style>
